import { DevNavBar } from "../components";

function Dev() {
    return (
        <div>
            <DevNavBar />
            <div className="Page">
                <h1>Dev page</h1>
            </div>
        </div>
    );
}

export default Dev;