import { VocabNavBar } from "../components";

function Vocab() {
    return (
        <div>
            <VocabNavBar />
            <div className="Page">
                <h1>Vocab page</h1>
            </div>
        </div>
    );
}

export default Vocab;